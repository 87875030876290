@import "../index.scss";

/* home */
.impressum {
  .impressumHeader {
    text-align: center;
    font-size: 3.5rem;
    color: var(--primary-color);
    margin-top: 5rem;

    @include respond(phone){
      font-size: 2.5rem;
    }

    &::after {
      content: "";
      display: block;
      width: 7rem;
      margin: 1rem auto;
      height: 0.3rem;
      background: var(--primary-color);
    }
  }
}
