@import "../index.scss";

/* about */
.about {
  height: 60vh;

  @include respond(phone) {
    height: auto;
  }

  .aboutContainer {
    width: 100%;
    margin: auto;
    height: 70vh;
    padding: 2rem;
    display: flex;
    align-items: center;
    pointer-events: none;

    @include respond(phone) {
      height: 50vh;
      padding: 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }

    .aboutContainerImg {
      width: 60%;
      height: 70%;
      border-radius: 3rem;

      @include respond(phone) {
        width: 100%;
        max-height: 500px;
      }
    }


    .aboutContainerDetails {
      width: 40%;
      text-align: center;
      background-color: var(--white);
      border-radius: 3rem;
      transform: translateX(-10rem);
      box-shadow: 0rem 0.44rem 1.81rem 0rem rgba(100, 100, 111, 0.2);

      @include respond(phone) {
        position: relative;
        width: 100%;
        transform: none;
        margin-top: 10px;
      }

      & h1 {
        font-size: 3rem;
        color: var(--primary-color);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      & p {
        font-size: 1.7rem;
        color: var(--grey);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 1.5rem;
        }
      }
    }

    .aboutContainerDetails2 {
      transform: translateX(10rem);
      
      
      @include respond(phone) {
        order: 1;
        transform: none;
      }
    }
  }
}
