@import "../index.scss";

/*  header */
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 650px;

  overflow: hidden;

  @include respond(phone) {
    height: 300px;
  }
}

.ccc{
  margin-top: 90px;

  @include respond(phone) {
    margin-top: 50px;
  }
}

.roundBorder{
  border-radius: 30px;

  @include respond(phone) {
    border-radius: 0;
  }
}
  